import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Layout from "../components/layout";
import HeroSection from "../screens/heroSection";
import Plans from "../screens/plans";

const Home = () => {
  return (
    <Layout>
      <HeroSection />



      {/* <section className="text-center py-16 bg-gray-50">
        <h1 className="text-4xl font-bold">
          Elevate Sales With Seamless Management
        </h1>
        <p className="mt-4 text-lg">
          Empower your team, streamline your processes, and boost revenue with
          our platform.
        </p>
        <div className="mt-6 space-x-4">
          <button className="bg-green-500 text-white px-6 py-3 rounded">
            Get Started
          </button>
          <button className="bg-white text-green-500 px-6 py-3 border border-green-500 rounded">
            Play Video
          </button>
        </div>
        <div className="mt-12 grid grid-cols-3 gap-6">
          <img src="/path-to-image1.png" alt="Sales Activity" />
          <img src="/path-to-image2.png" alt="Team Work" />
          <img src="/path-to-image3.png" alt="Laptop Screen" />
        </div>
      </section> */}
      <Plans />

      <section class="bg-white border">
        <div class="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
          <div class="col-span-2 mb-8">
            <p class="text-lg font-medium text-purple-600 ">
              Trusted Worldwide
            </p>
            <h2 class="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl ">
              Trusted by over 600 million users and 10,000 teams
            </h2>
            <p class="font-light text-gray-500 sm:text-xl ">
              Our rigorous security and compliance standards are at the heart of
              all we do. We work tirelessly to protect you and your customers.
            </p>
            <div class="pt-6 mt-6 space-y-4 border-t border-gray-200 ">
              <div>
                <a
                  class="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800  "
                  href="#"
                >
                  Explore Legality Guide
                  <svg
                    class="w-5 h-5 ml-1"
                    fill="currentColor"
                    viewbox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
              <div>
                <a
                  class="inline-flex items-center text-base font-medium text-purple-600 hover:text-purple-800  "
                  href="#"
                >
                  Visit the Trust Center
                  <svg
                    class="w-5 h-5 ml-1"
                    fill="currentColor"
                    viewbox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clip-rule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div class="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
            <div>
              <svg
                class="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 "
                fill="currentColor"
                viewbox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
                  fill-rule="evenodd"
                ></path>
              </svg>
              <h3 class="mb-2 text-2xl font-bold ">
                99.99% uptime
              </h3>
              <p class="font-light text-gray-500 ">
                For Fixlabs, with zero maintenance downtime
              </p>
            </div>
            <div>
              <svg
                class="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 "
                fill="currentColor"
                viewbox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
              </svg>
              <h3 class="mb-2 text-2xl font-bold ">
                600M+ Users
              </h3>
              <p class="font-light text-gray-500 ">
                Trusted by over 600 milion users around the world
              </p>
            </div>
            <div>
              <svg
                class="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 "
                fill="currentColor"
                viewbox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
                  fill-rule="evenodd"
                ></path>
              </svg>
              <h3 class="mb-2 text-2xl font-bold ">
                100+ countries
              </h3>
              <p class="font-light text-gray-500 ">
                Have used Fixlabs to create functional websites
              </p>
            </div>
            <div>
              <svg
                class="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 "
                fill="currentColor"
                viewbox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
              </svg>
              <h3 class="mb-2 text-2xl font-bold ">
                5+ Million
              </h3>
              <p class="font-light text-gray-500 ">
                Transactions per day
              </p>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  );
};

export default Home;