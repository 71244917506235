import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

const MegaMenu = (isOpen) => {
  console.log("isOpen", isOpen);
  return (
    <div>
      {isOpen.isOpen && (
        <div className="absolute left-0 top-full w-screen bg-white shadow-lg border-t">
          <div className="flex max-w-screen-xl mx-auto p-8">
            {/* Left Sidebar */}
            <div className="w-1/4 border-r pr-6">
              <h3 className="text-lg font-semibold">Categories</h3>
              <ul className="mt-4 space-y-2">
                <li>
                  <a href="#" className="hover:underline">
                    Sales
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Marketing
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Commerce and POS
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Service
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Finance
                  </a>
                </li>
              </ul>
              <Link to='/products' className="mt-4 bg-gray-200 px-4 py-2 rounded block">
                Explore All Products
              </Link>
            </div>
            {/* Right Side - Product Grid */}
            <div className="w-3/4 pl-6">
              <h3 className="text-lg font-semibold">Sales</h3>
              <div className="grid grid-cols-3 gap-4 mt-4">
                {/* <div className="p-4 border rounded hover:bg-gray-100">
                  <h4 className="font-semibold">CRM</h4>
                  <p className="text-sm">
                    Comprehensive CRM platform for customer-facing teams.
                  </p>
                </div>
                <div className="p-4 border rounded hover:bg-gray-100">
                  <h4 className="font-semibold">Bookings</h4>
                  <p className="text-sm">
                    Appointment scheduling app for consultations.
                  </p>
                </div>
                <div className="p-4 border rounded hover:bg-gray-100">
                  <h4 className="font-semibold">Sign</h4>
                  <p className="text-sm">
                    Digital signature app for businesses.
                  </p>
                </div>
                <div className="p-4 border rounded hover:bg-gray-100">
                  <h4 className="font-semibold">Forms</h4>
                  <p className="text-sm">
                    Build online forms for every business need.
                  </p>
                </div>
                <div className="p-4 border rounded hover:bg-gray-100">
                  <h4 className="font-semibold">RouteIQ</h4>
                  <p className="text-sm">
                    Comprehensive sales map and route planning solution.
                  </p>
                </div>
                <div className="p-4 border rounded hover:bg-gray-100">
                  <h4 className="font-semibold">Thrive</h4>
                  <p className="text-sm">
                    Complete loyalty and affiliate management platform.
                  </p>
                </div> */}
                <Link to="/products/sales/inficonnect">
                  <div className="p-4 border rounded cursor-pointer hover:bg-gray-100">
                    <div className="flex justify-between items-center mb-2">
                      <h4 className="text-lg font-semibold">Infi Connect</h4>
                      <img
                        src="/inficonnect/logo.png"
                        alt="Product Catalog Illustration"
                        className="w-10 rounded-lg transition-transform transform hover:scale-105"
                      />
                    </div>
                    <p className="text-sm">
                      A comprehensive platform for connecting vendors and brands, empowering partnership and loyalty management.
                    </p>
                  </div>
                </Link>
                <div className="p-4 border rounded cursor-pointer hover:bg-gray-100">
                  <h4 className="text-lg font-semibold">Zero Click</h4>
                  <p className="text-sm">
                    Complete loyalty and affiliate management platform.
                  </p>
                </div>
                <div className="p-4 border rounded cursor-pointer hover:bg-gray-100">
                  <h4 className="text-lg font-semibold">Express Bazaar</h4>
                  <p className="text-sm">
                    Your one-stop online marketplace offering a wide range of products from trusted vendors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MegaMenu;
