import React from 'react'
import Layout from '../components/layout'
import { Link } from 'react-router-dom'

const Inficonnect = () => {
  return (
    <Layout>
      <div className="bg-gray-50 min-h-screen flex flex-col items-center">
        <div className="sticky px-3 md:px-7 top-0 z-10 lg:px-44 flex justify-between items-center header bg-[#f8f9fb] shadow-sm w-full">
          <div className='w-auto flex justify-center'>
            <img src="/inficonnect/logo512.png" className="h-20 md:h-28" />
          </div>
          <div className="flex lg:w-auto lg:order-1">
            <ul className="flex text-sm md:text-base md:flex-row lg:space-x-8 lg:mt-0 text-lg font-normal">
              <li>
                <Link
                  className="block py-2 px-2 md:px-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                  to="/signup"
                >
                  Sign Up

                </Link>
              </li>
              <li>
                <Link
                  className="block py-2 px-2 md:px-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                  to="https://developer.fixlabs.ai"
                >
                  Documentation

                </Link>
              </li>
              {/* <li>
                <Link
                  className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                  to="/extensions"
                >
                  Extensions

                </Link>
              </li>
              <li>
                <Link
                  className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                  to="security"
                >
                  Security

                </Link>
              </li>
              <li>
                <Link
                  className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                  to="/implementation"
                >
                  Implementation
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
        {/* Hero Section */}
        <section className="relative w-full max-w-7xl mx-auto px-4 md:px-8 py-12 md:py-20 flex flex-col-reverse md:flex-row items-center">
          {/* Left Section - Text and CTA */}
          <div className="w-full md:w-1/2 space-y-6 md:space-y-8">
            <h1 className="text-2xl md:text-3xl md:text-5xl font-extrabold text-indigo-700 leading-tight">
              Power Up Your Fashion Brand with Stunning Product Catalogs
            </h1>
            <p className="text-gray-600 text-base md:text-xl">
              Transform your product collection into captivating, shareable catalogs. Engage your customers and showcase your brand's latest styles with ease.
            </p>
            <div className="flex space-x-4">
              <Link to="/signup">
                <button className="bg-indigo-700 text-white px-6 py-3 rounded-lg font-semibold hover:bg-indigo-800 transition duration-300">
                  Get Started
                </button>
              </Link>
              <Link to="https://developer.fixlabs.ai">
                <button className="bg-transparent border border-indigo-700 text-indigo-700 px-6 py-3 rounded-lg font-semibold hover:bg-indigo-100 transition duration-300">
                  API Docs
                </button>
              </Link>
            </div>
          </div>

          {/* Right Section - Image */}
          <div className="w-1/2 mb-8 md:mb-0 flex justify-center">
            <img
              src="/inficonnect/logo512.png" // Replace with a relevant image
              alt="Product Catalog Illustration"
              className="w-full max-w-sm md:max-w-md rounded-lg transition-transform transform hover:scale-105"
            />
          </div>
        </section>

        {/* Features Section */}
        <section className="bg-white w-full py-12 md:py-20 px-4 md:px-8">
          <div className="max-w-5xl mx-auto text-center">
            <h2 className="text-2xl md:text-4xl font-semibold text-gray-800 mb-6">Why Choose Our Platform?</h2>
            <p className="text-gray-600 text-lg mb-12">
              We provide everything you need to make your brand stand out and simplify the catalog creation process.
            </p>

            {/* Feature Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
              <FeatureCard
                title="Easy Catalog Creation"
                description="Use our intuitive builder to design catalogs that match your brand."
                icon="💡"
              />
              <FeatureCard
                title="Customizable Templates"
                description="Choose from a variety of templates to make each catalog unique."
                icon="🎨"
              />
              <FeatureCard
                title="Seamless Sharing"
                description="Share your catalogs online with just a click, and attract more customers."
                icon="🚀"
              />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Inficonnect

function FeatureCard({ title, description, icon }) {
  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105">
      <div className="text-4xl">{icon}</div>
      <h3 className="text-xl font-semibold text-gray-800 mt-4">{title}</h3>
      <p className="text-gray-600 mt-2">{description}</p>
    </div>
  );
}