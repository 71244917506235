// src/components/SignUp.js
import React, { useState } from "react";
import axios from 'axios';
import Layout from "../components/layout";
import { redirect } from "react-router-dom";
import { useNavigate } from 'react-router-dom';



const SignUp = () => {
  const navigate = useNavigate();

  const [errorMessage,setErrorMessage] = useState("");

  const [formData, setFormData] = useState({
    storeName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    country: "IN",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    purpose: "",
    termsAccepted: false,
  });

  const requestData = {
    storeName: formData.storeName,
    // ownerName: "Shubham Sharma",
    ownerName: `${formData.firstName} ${formData.lastName}`,
    ownerEmail: formData.email,
    ownerPhone: formData.phone,
    ownerPassword: formData.password,
    addressDetails: {
      address: formData.address,
      city: formData.city,
      province: formData.state,
      zip: formData.zipcode,
      country: formData.country,
      province_code: "",
      country_code: formData.country
    }
  }
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (e) => {
    setErrorMessage("");
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://catalog-pro-engine.onrender.com/api/vendor/create',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(requestData)
    };

    try {
      const response = await axios.request(config);
      console.log('Response:',(response?.data?.message));
      console.log('Response success:', (response?.data?.success));
      if (response?.data?.success === true) { 
        navigate("/thankyou");
      }else{
        setErrorMessage(response?.data?.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Layout>
      {/* <div className=" flex items-center justify-center bg-gray-100  md:p-8">
        <div className="bg-white mt-20 p-4 md:p-8 md:rounded-lg md:shadow-md  w-full max-w-5xl">
          <h2 className="text-3xl text-slate-600 font-semibold text-center text-gray-800 pb-5 py-2">Sign Up</h2>
          <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700 py-2">First Name</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
                placeholder="Enter your first name"
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 py-2">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
                placeholder="Enter your last name"
                required
              />
            </div>

            <div className="">
              <label className="block text-gray-700 py-2">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="">
              <label className="block text-gray-700 py-2">Phone</label>
              <input
                type="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
                placeholder="Enter your phone no."
                required
              />
            </div>

            <div>
              <label className="block text-gray-700 py-2">Password</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
                  placeholder="Enter your password"
                  required
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-500"
                >
                  {showPassword ? "🙈" : "👁️"}
                </span>
              </div>
            </div>

            <div>
              <label className="block text-gray-700 py-2">Confirm Password</label>
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
                placeholder="Confirm your password"
                required
              />
            </div>
            <div className="md:col-span-2">
              <label className="block text-gray-700 py-2">Country</label>
              <select
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
                required
              >
                <option value="" disabled>Select your Country</option>
                <option value="india">India</option>
              </select>
            </div>
            <div className="md:col-span-2">
              <label className="block text-gray-700 py-2">Address</label>
              <textarea
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
                placeholder="Enter your Address"
                required
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:col-span-2">

            <div>
              <label className="block text-gray-700 py-2">City</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
                placeholder="City"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 py-2">State</label>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
                placeholder="State"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 py-2">Zipcode</label>
              <input
                type="text"
                name="zipcode"
                value={formData.zipcode}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
                placeholder="Zipcode"
                required
              />
            </div>
            </div>

            <div className="md:col-span-2">
              <label className="block text-gray-700 py-2">Purpose</label>
              <select
                name="purpose"
                value={formData.purpose}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-slate-200"
                required
              >
                <option value="" disabled>Select your purpose</option>
                <option value="personal">Personal Use</option>
                <option value="business">Business Use</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleInputChange}
                className="mr-2"
                required
              />
              <label className="text-gray-700">I agree to the terms and conditions</label>
            </div>

            <button
              type="submit"
              className="w-full bg-slate-500 text-white py-2 rounded-md hover:bg-slate-600 transition duration-300"
            >
              Sign Up
            </button>
          </form>
        </div>
      </div> */}
      <div className="flex items-center justify-center bg-gray-100 min-h-screen md:p-8">
        <div className="bg-gray-100 mt-20 p-6 md:p-10 md:rounded-lg  w-full max-w-5xl">
          <h2 className="text-2xl md:text-4xl text-slate-700 font-bold text-center mb-6">Sign Up</h2>
          <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-6">
            
            

            {/* First Name */}
            <div>
              <label className="block text-slate-600 mb-2">First Name</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-400"
                placeholder="Enter your first name"
                required
              />
            </div>

            {/* Last Name */}
            <div>
              <label className="block text-slate-600 mb-2">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-400"
                placeholder="Enter your last name"
                required
              />
            </div>

            {/* Email */}
            <div>
              <label className="block text-slate-600 mb-2">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-400"
                placeholder="Enter your email"
                required
              />
            </div>

            {/* Phone */}
            <div>
              <label className="block text-slate-600 mb-2">Phone</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-400"
                placeholder="Enter your phone number"
                required
              />
            </div>

            {/* Password */}
            <div>
              <label className="block text-slate-600 mb-2">Password</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-400"
                  placeholder="Enter your password"
                  required
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-500"
                >
                  {showPassword ? "🙈" : "👁️"}
                </span>
              </div>
            </div>

            {/* Confirm Password */}
            <div>
              <label className="block text-slate-600 mb-2">Confirm Password</label>
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-400"
                placeholder="Confirm your password"
                required
              />
            </div>

            {/* Country */}
            <div className="md:col-span-2">
              <label className="block text-slate-600 mb-2">Country</label>
              <select
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-400"
                required
              >
                <option value="" disabled>Select your Country</option>
                <option value="IN">India</option>
              </select>
            </div>

            {/* Address */}
            <div className="md:col-span-2">
              <label className="block text-slate-600 mb-2">Address</label>
              <textarea
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-400"
                placeholder="Enter your address"
                required
              />
            </div>

            {/* City, State, Zipcode */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:col-span-2">
              <div>
                <label className="block text-slate-600 mb-2">City</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-400"
                  placeholder="City"
                  required
                />
              </div>
              <div>
                <label className="block text-slate-600 mb-2">State</label>
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-400"
                  placeholder="State"
                  required
                />
              </div>
              <div>
                <label className="block text-slate-600 mb-2">Zipcode</label>
                <input
                  type="text"
                  name="zipcode"
                  value={formData.zipcode}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-400"
                  placeholder="Zipcode"
                  required
                />
              </div>
            </div>

            {/* Purpose */}
            <div className="md:col-span-2">
              <label className="block text-slate-600 mb-2">Purpose</label>
              <select
                name="purpose"
                value={formData.purpose}
                onChange={handleInputChange}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-400"
                required
              >
                <option value="" disabled>Select your purpose</option>
                <option value="personal">Personal Use</option>
                <option value="business">Business Use</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="md:col-span-2">
              <label className="block text-slate-600 mb-2">Store Name</label>
              <input
                type="text"
                name="storeName"
                value={formData.storeName}
                onChange={handleInputChange}
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-400"
                placeholder="Enter your Store name"
                required
              />
            </div>

            {/* Terms and Conditions */}
            <div className="flex items-center md:col-span-2">
              <input
                type="checkbox"
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleInputChange}
                className="mr-2"
                required
              />
              <label className="text-slate-600">I agree to the terms and conditions</label>
            </div>

            {errorMessage !== "" ? <p className="text-red-500">{errorMessage}</p>: null}

            {/* Submit Button */}
            <div className="flex items-center md:col-span-2">

              <div className="w-full flex justify-center mt-4">
                <button
                  type="submit"
                  className="w-2/4 md:w-3/4 max-w-xs text-sm md:text-base bg-slate-800 text-white py-3 rounded-md hover:bg-slate-900 transition duration-300"
                  onClick={handleSubmit}
                >
                  Sign Up
                </button>
              </div>
            </div>

          </form>
        </div>
      </div>

    </Layout>
  );
}

export default SignUp;