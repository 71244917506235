import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../pages/home'
import Products from '../pages/products'
import NotFound from '../pages/notFound'
import SignUp from '../pages/signup'
import Contact from '../pages/contact'
import Inficonnect from '../pages/inficonnect'
import EmailVerification from '../pages/emailVerification'
import Enterprise from '../pages/enterprise'
import Customers from '../pages/customers'

const RoutesPage = () => {
  return (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/products' element={<Products/>}/>
            <Route path='/products/sales/inficonnect' element={<Inficonnect/>}/>
            <Route path='/signup' element={<SignUp/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/enterprise' element={<Enterprise/>}/>
            <Route path='/customers' element={<Customers/>}/>
            <Route path='/thankyou' element={<EmailVerification/>}/>
            <Route path='*' element={<NotFound/>}/>
        </Routes>
    </BrowserRouter>
  )
}

export default RoutesPage