import React, { useState, useRef, useEffect } from "react";
import MegaMenu from "./megaMenu";
import { FaAngleDown, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';


const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const location = useLocation();
  const isEnterprisesPage = location.pathname.includes('/inficonnect');

  return (
    <header className={`z-10 w-full border-b ${isEnterprisesPage ? '' : 'fixed'}`}>
      <nav className="bg-white py-6">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
          <Link className="flex items-center" to="/">
            <img src="/logo.jpg" alt="Logo" className="h-12" />
          </Link>
          <div className="flex items-center lg:order-2">
            <div className="space-x-4 hidden md:block">
              <Link
                // to="/signin"
                to="https://fixlabs-inficonnect.vercel.app/"
                className="bg-white text-slate-900 font-semibold hover:bg-slate-900 hover:text-white  px-4 py-2 rounded border-[1px] border-slate-900"
              >
                Sign In
              </Link>
            </div>
            <button
              aria-controls="mobile-menu-2"
              aria-expanded={isMobileMenuOpen}
              onClick={toggleMobileMenu}
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              type="button"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  fillRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div className="hidden lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              <li className="" ref={menuRef}>
                <div>
                  <button
                    onClick={toggleMenu}
                    className="flex items-center text-gray-700 hover:text-purple-700 focus:outline-none"
                  >
                    Products <FaAngleDown className="ml-2" />
                  </button>
                  <MegaMenu isOpen={isMenuOpen} />
                </div>
              </li>
              <li>
                <Link
                  className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                  to="/enterprise"
                >
                  Enterprise
                </Link>
              </li>
              <li>
                <Link
                  className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                  to="/customers"
                >
                  Customers
                </Link>
              </li>
              {/* <li>
                <Link
                  className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                  to="/company"
                >
                  Company
                </Link>
              </li> */}
              <li>
                <Link
                  className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                  to="https://developer.fixlabs.ai"
                >
                  Developer Center
                </Link>
              </li>
              <li>
                <Link
                  className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                  to="/contact"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 z-40 bg-black bg-opacity-50 flex justify-end lg:hidden">
          <div
            className={`transform top-0 right-0 w-64 bg-white h-full shadow-lg transition-transform duration-300 ease-in-out ${
              isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <button
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 focus:outline-none"
              onClick={closeMobileMenu}
            >
              <FaTimes className="w-6 h-6" />
            </button>
            <ul className="p-6 space-y-4">
              <li>
                <Link
                  className="block text-gray-700 hover:text-purple-700"
                  to="/products"
                  onClick={closeMobileMenu}
                >
                  Products
                </Link>
              </li>
              <li>
                <Link
                  className="block text-gray-700 hover:text-purple-700"
                  to="/company"
                  onClick={closeMobileMenu}
                >
                  Company
                </Link>
              </li>
              <li>
                <Link
                  className="block text-gray-700 hover:text-purple-700"
                  to="/features"
                  onClick={closeMobileMenu}
                >
                  Features
                </Link>
              </li>
              <li>
                <Link
                  className="block text-gray-700 hover:text-purple-700"
                  to="/contact"
                  onClick={closeMobileMenu}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  className="bg-black text-white px-4 py-2 rounded w-full"
                  to="/contact"
                  onClick={closeMobileMenu}
                >
                  Sign In
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;