import React from 'react'
import Layout from '../components/layout'
import { Link } from 'react-router-dom'

function Enterprise() {
    return (
        <Layout>
            <div className="min-h-screen  w-full" >
                <div className="text-center w-full relative">
                    {/* <div className="sticky top-0 z-10 px-20 flex justify-between items-center header bg-[#f8f9fb] shadow-sm">
                        <div className='w-full lg:w-auto flex justify-center'>
                            <img src="/inficonnect/logo512.png" className="h-28" />
                        </div>
                        <div className="hidden lg:flex lg:w-auto lg:order-1">
                            <ul className="flex flex-col font-medium lg:flex-row lg:space-x-8 lg:mt-0 text-lg font-normal">
                                <li>
                                    <Link
                                        className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                                        to="/platform"
                                    >
                                        Platform

                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                                        to="/verticals"
                                    >
                                        Verticals

                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                                        to="/extensions"
                                    >
                                        Extensions

                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                                        to="security"
                                    >
                                        Security

                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-purple-700 lg:p-0"
                                        to="/implementation"
                                    >
                                        Implementation
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                    <div className='bg-[#f8f9fb] px-2 lg:px-80 py-8'>
                        <h1 className='text-[28px] md:text-[44px] mb-4 md:mb-8'>Accelerate enterprise growth with customisable software solutions</h1>
                        <p className='text-sm md:text-[24px] leading-2 md:leading-8 px-5 mb-8 md:mb-10'>Deliver exceptional customer and employee experiences, enable seamless collaboration, automate business processes, and gather business intelligence—all with a robust software technology platform built in India.</p>
                        <button className='px-5 py-3 bg-red-500 text-white text-sm md:text-lg '>CONTACT SALES</button>
                    </div>
                    <div className='w-full h-40 md:h-96 bg-[#f8f9fb] '>
                        <img src="/inficonnect/background.png" className='w-full h-full object-cover' alt="Accelerate enterprise growth with customisable software solutions" />
                    </div>
                    
                    {/* <div className="relative">
                        <div className="bg-blue-700 text-white py-10">
                            <div className="text-center mb-4">
                                <h2 className="text-lg font-semibold">TRUSTED BY TEAMS AT</h2>
                            </div>
                            <div className="flex justify-center space-x-8 mb-8">
                                <img src="/path/to/tata-logo.png" alt="TATA" className="h-12" />
                                <img src="/path/to/deloitte-logo.png" alt="Deloitte" className="h-12" />
                                <img src="/path/to/bigbasket-logo.png" alt="Big Basket" className="h-12" />
                                <img src="/path/to/bluestar-logo.png" alt="Blue Star" className="h-12" />
                                <img src="/path/to/npci-logo.png" alt="NPCI" className="h-12" />
                                <img src="/path/to/bosch-logo.png" alt="Bosch" className="h-12" />
                            </div>
                        </div>

                        <div className="absolute top-1/2 left-1/2 w-full transform -translate-x-1/2 -translate-y-1/2 bg-white text-gray-800 rounded-lg p-8 max-w-5xl mx-auto shadow-lg">
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
                                <div className="flex flex-col items-center">
                                    <img src="/path/to/gartner-logo.png" alt="Gartner" className="h-12 mb-2" />
                                    <p>Zoho has been featured across multiple Gartner Magic Quadrants</p>
                                </div>
                                <div>
                                    <h3 className="font-semibold">A Visionary in the 2024 Magic Quadrant™ for Sales Force Automation Platforms</h3>
                                </div>
                                <div>
                                    <h3 className="font-semibold">A Niche Player in the 2023 Magic Quadrant™ for Analytics and Business Intelligence Platforms</h3>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center mt-8">
                                <div className="flex flex-col items-center">
                                    <img src="/path/to/isg-logo.png" alt="ISG" className="h-12 mb-2" />
                                    <p>Zoho is a Rising Star in Provider Lens Future of Work (Workplace) Solutions (UCCaaS), 2023</p>
                                </div>
                                <div className="flex flex-col items-center">
                                    <img src="/path/to/nucleus-logo.png" alt="Nucleus" className="h-12 mb-2" />
                                    <p>A Leader in the Talent Acquisition Technology Value Matrix 2023</p>
                                </div>
                                <div className="flex flex-col items-center">
                                    <img src="/path/to/dresner-logo.png" alt="Dresner" className="h-12 mb-2" />
                                    <p>An Overall Leader in Dresner's 2023 Wisdom of Crowds Business Intelligence Market Study</p>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        </Layout>

    )
}

export default Enterprise